import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Box } from "@mui/material";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import AvniPng from './assets/images/rj/avni.png'
import KavneetPng from './assets/images/rj/kavneet.png'
import Rahul_makinPng from './assets/images/rj/rahul_makin.png'
import VisheshPng from './assets/images/rj/vishesh.png'
import {Typography} from "@mui/material";
// const images = [
//   {
//     label: "San Francisco – Oakland Bay Bridge, United States",
//     imgPath:
//       "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
//   },
//   {
//     label: "Bird",
//     imgPath:
//       "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
//   },
//   {
//     label: "Bali, Indonesia",
//     imgPath:
//       "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250",
//   },
//   {
//     label: "Goč, Serbia",
//     imgPath:
//       "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
//   },
// ];


export default function OurRjs() {

  const [images, setImages] = useState<any[]>([]);

  const item = [
    {title:"Love Coach", name:"Rahul Makin", img:Rahul_makinPng, about:"He is known as the official love coach,the love guru. He is the host of The Burrah Show where he interviews artists from Punjabi as well as the Bollywood Industry. This gym freak does not only use his force to lift weights at the gym but is also the man who has used his force in creating International Punjabi Fever."},
    {title:"RJ", name:"Vishesh", img:VisheshPng, about:"He’s a true Amritsari who starts his day with a gedi,his five hour long show called ‘The Gedi Route’ . His show consists of all the information and the gossips within the Punjabi Music Industry. He’s a big foodie who is also known for his humorous Amritsari videos on Instagram."},
    {title:"RJ", name:"Kavneet", img:KavneetPng, about:"She is the host of the show ‘Shades of Love’. People might need coffee for energy but her source of energy are Shayris,said at the beginning of each show of hers.  On her show she talks about different situations that exist in love."},
    {title:"RJ", name:"Avni", img:AvniPng, about:" She is the host of ‘Kudi Top Notch’ and also one herself . She tells all the happening and top notch gen z gossips from Punjabi to Bollywood to instagram trends or any other  news. She is also a movie critique and known for her role Baby Aunty."}
  ]

  useEffect(()=>{
    getCrousel()
  },[])

  const getCrousel = async() =>{
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}crousel`
    );

    if(response.status == 200)
      {
        const data = await response.json();
        if(data.docs)
          {
            setImages(data.docs)
          }
      }
  }

  const CustomPrevArrow = (onClickHandler:any, hasPrev:any, label:any) => {
    return hasPrev && (
      
      <button type="button" onClick={onClickHandler} title={label} className="crouselNavigator" style={{ left:"0.5em", top:"8em", position:"absolute", background:"transparent", border:"none", zIndex:"3", cursor:"pointer"}}>
        <ArrowBackIos sx={{color:"#fff", fontSize:"25px"}} ></ArrowBackIos>
      </button>
    );
  };

  const CustomNextArrow = (onClickHandler:any, hasPrev:any, label:any) => {
    return hasPrev && (
      <button type="button" onClick={onClickHandler} title={label} className="crouselNavigator" style={{ right:"0.5em", top:"8em", position:"absolute", background:"transparent", border:"none", zIndex:"3", cursor:"pointer"}}>
        <ArrowForwardIos sx={{color:"#fff", fontSize:"25px"}} ></ArrowForwardIos>
      </button>
    );
  };
  // useEffect(()=>{
  //   console.log(images)
  // },[images]);

  return (  
    <Box sx={{ maxWidth:"650px"}}>
    <Box sx={{}}>
        <Carousel
          infiniteLoop={true}
          showIndicators={false}
          showArrows={true}
          showThumbs={false}
          stopOnHover={true}
          // centerMode={true}
          showStatus={false}
          autoPlay={true}
          renderArrowPrev={CustomPrevArrow}
          renderArrowNext={CustomNextArrow}
          
        >
          {item.map((v,i) => {
            return (
              <div key={i}>
                <Typography fontWeight="600" fontSize="14px">{v.title}</Typography>
                <Typography fontWeight="500" fontSize="22px" marginBottom="0em">{v.name}</Typography>
                <img src={v.img}  />
                <Typography fontSize="14px" color="#777" marginTop="1em">{v.about}</Typography>
              </div>
            );
          })}
        </Carousel>
        </Box>
        </Box>
    
  );
}
