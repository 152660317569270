import { PauseCircleOutline, PlayCircleOutline, PlayCircle, PauseCircle } from "@mui/icons-material";
import { Grid, Card, Typography, IconButton, Box } from "@mui/material";
import { useEffect, useState } from "react";
import moment from 'moment-timezone';

export default function PlayPanel({
  playPause,
  playing = false,
  thumbnail = "",
  songTitle = "",
  streamStart = "",
}: {
  playPause: () => void;
  playing: boolean;
  thumbnail: string | false;
  songTitle: string;
  streamStart: string;
}) {

  const [currentTime, setCurrentTime] = useState<string>("");

  useEffect(() => {
    
    const interval = setInterval(() =>{ 
      const ist = moment.utc(new Date(), 'hh:mm:ss A').tz('Asia/Kolkata').format('hh:mm:ss A');
      setCurrentTime(ist)}, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // useEffect(()=>{
  //   console.log(songTitle, "song titiel")
  // },[songTitle])

  return (
    <>
      <Grid
        container
        sx={{
          background: "#1B1919",
          borderRadius: {md:"1.3em", xs:"0.2em"},
          width: "100%",
        }}
        className="shadow-0"
      >
        {/* <Grid item xs={2}>
      <Card
        sx={{
          height: { md: "100px", xs: "70px" },
          minHeight: { xs: "70px" },
          width: "auto",
          borderRadius: { md: "1em", xs: "0.5em" },
          backgroundColor: "transparent",
          backgroundImage: {
            md: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%), url('${
              thumbnail || "./images/default.jpg"
            }')`,
            xs: `url('${thumbnail || "./images/default.jpg"}')`,
          },
          backgroundSize: {
            md: "auto 35%, cover",
            xs: "cover",
          },
          backgroundPosition: {
            md: "center bottom, center",
            xs: "center",
          },
          backgroundRepeat: "no-repeat",
          transition: "background-image 0.5s ease-in-out",
        }}
      ></Card>
    </Grid> */}
        <Grid item display="flex" flexWrap="wrap" xs={12}>
          <IconButton
            aria-label="pause"
            onClick={playPause}
            sx={{ color: "white", marginRight:{md:"0.5em", xs:"0em"} }}
          >
            {playing ? (
              <PauseCircle sx={{ fontSize: { xs: "45px", md: "70px" } }} />
            ) : (
              <PlayCircle sx={{ fontSize: { xs: "45px", md: "70px" } }} />
            )}
          </IconButton>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignContent="center"
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 500,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "16em",
                fontSize:{md:"24px",xs:"16px"},
                textTransform:'capitalize'
              }}
            >
              {songTitle}
              <br />
            </Typography>
            <Typography sx={{ fontWeight: "200", lineHeight:"1.1em", color: "#999", fontSize:{md:"22px", xs:"16px"} }}>
              {currentTime}{currentTime?' IST':''} 
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
