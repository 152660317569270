import {
  Avatar,
  Card,
  CardContent,
  Divider,
  IconButton,
  InputBase,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { Google, Person } from "@mui/icons-material";
import React from "react";
import SendIcon from "@mui/icons-material/Send";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "./firebase";
import { useAuth } from "./context";
import socket from "./ws-client.service";

const Chat: React.FC<{
  onLogin: () => void;
  ad: any;
  messages: { message: string; name: string; photo: string }[];
}> = ({ onLogin, ad, messages }) => {
  const { isAuthenticated, user, login } = useAuth();
  const [message, setMessage] = React.useState<string>("");
  const ListRef = React.useRef<HTMLUListElement | null>(null);
  const handleLogin = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        login(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    ListRef.current?.scrollTo(0, ListRef.current?.scrollHeight);
  }, [messages]);

  const sendMessage = async () => {
    if (message.length >= 2) {
      await socket.client.request({
        method: "POST",
        path: "/h",
        payload: {
          message,
          name: user.displayName,
          photo: user.photoURL,
        },
      });
      setMessage("");
    }
  };

  return (
    <Card id="chat" sx={{ background: "transparent" }}>
      <CardContent
        sx={{
          padding: 0,
          height: "100%",
          minWidth: { xs: "350px", md: "unset" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <List
          ref={ListRef}
          sx={{ width: "100%", overflow: "auto", padding: 0 }}
          subheader={<li />}
        >
          <ListSubheader sx={{ color: "#fff" }}>Chat</ListSubheader>
          {messages.map((msg, i) => (
            <React.Fragment key={i}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={msg.name} src={msg.photo} />
                </ListItemAvatar>
                <ListItemText
                  secondary={
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {msg.name}:<br />
                      {`${msg.message}`}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider variant="fullWidth" component="li" />
            </React.Fragment>
          ))}
        </List>
        <List sx={{ width: "100%", bgcolor: "transparent", padding: 0 }}>
          {/* <ListItem alignItems="flex-start">
                    <Link href={ad?.url}>
                        <img src={`${ad?.image || './images/chat_ad.png'}`} alt="Ad" width="100%" />
                    </Link>
                </ListItem> */}
          {/* <Divider variant="fullWidth" component="li" /> */}
          {!isAuthenticated && (
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Grid container>
                <Grid xs={12} md={6}>
                  <Button
                    startIcon={<Person />}
                    sx={{
                      borderRadius: "30px",
                      marginBottom: "1em",
                      borderColor: "#fff",
                      color: "#ddd",
                    }}
                    size="large"
                    variant="outlined"
                    onClick={onLogin}
                  >
                    <span>Login</span>
                  </Button>
                </Grid>
                <Grid xs={12} md={6}>
                  <Button
                    startIcon={<Google />}
                    sx={{
                      borderRadius: "30px",
                      marginBottom: "1em",
                      borderColor: "#fff",
                      color: "#ddd",
                    }}
                    size="large"
                    variant="outlined"
                    onClick={handleLogin}
                  >
                    <span>Login with google</span>
                  </Button>
                </Grid>
              </Grid>
              {/* <ListItemButton >
                    <Avatar style={{ backgroundColor: "transparent" }}>
                        <InfoOutlinedIcon color="info" />
                    </Avatar>
                    <ListItemText
                        secondary={
                            <Typography
                                sx={{ display: 'inline', paddingLeft: "5px" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Login with google
                            </Typography>
                        }
                    />
                </ListItemButton>
                */}
            </Box>
          )}
          {isAuthenticated && (
            <ListItem>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Type here..."
                inputProps={{ "aria-label": "type here" }}
                value={message}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMessage(event.target.value);
                }}
                onKeyDown={(evt) => {
                  if (evt.key === "Enter") {
                    sendMessage();
                  }
                }}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="send"
                onClick={sendMessage}
              >
                <SendIcon />
              </IconButton>
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default Chat;
