import React, { useEffect, useState, FormEvent } from "react";
import { logEvent } from "firebase/analytics";
import "./App.css";
import {
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  Box,
  Popover,
  Fab,
  Button,
  Card,
  BottomNavigation,
  BottomNavigationAction,
  ListItem,
  List,
  TextField,
  Link,
  Alert,
  MenuList,
  MenuItem,
  ListItemIcon,
  InputAdornment,
} from "@mui/material";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import PauseCircleOutlineRoundedIcon from "@mui/icons-material/PauseCircleOutlineRounded";
import { Close, Chat as MChatICon, Share } from "@mui/icons-material";
import {
  Apple,
  FacebookOutlined,
  Favorite,
  Google,
  Send as SendIcon,
  PriorityHigh as PriorityHighIcon,
  Drafts as DraftsIcon,
} from "@mui/icons-material";
import Ad from "./Ad";
import Header from "./Header";
import Chat from "./Chat";
import Footer from "./Footer";
import MenuBar from "./MenuBar";
import SongThumbnail from "./SongThumbnail";
import AuthContextProvider from "./AuthContextProvider";
import { analytics, share } from "./firebase";
import socket from "./ws-client.service";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import MainCrousel from "./MainCrousel";

import InstaImg from "./assets/images/instagram.png";
import FbImg from "./assets/images/facebook.png";
import YtImg from "./assets/images/youtube.png";
import RestoreIcon from "@mui/icons-material/Restore";

import {
  Home,
  Person,
  PresentToAllOutlined,
  Sensors,
} from "@mui/icons-material";
import Shows from "./Shows";
import PlayPanel from "./PlayPanel";
import Logo from "./assets/images/logo.png";
import HomeIcon from "./assets/images/IconHome.png";
import ShowsIcon from "./assets/images/Group 871.png";
import ChatIcon from "./assets/images/Union.png";
import ShareIcon from "./assets/images/Frame 374.png";
import { useAuth } from "./context";
import Main from "./Main";

// import * as gtag from 'gtag';

function App() {
  // console.log = () => {};

  // const router = useRouter();

  // useEffect(() => {
  //   const handleRouteChange = (url) => {
  //     gtag.pageview(url);
  //   };
  //   router.events.on('routeChangeComplete', handleRouteChange);
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange);
  //   };
  // }, [router.events]);

  return (  
    <AuthContextProvider>
      <Main />
    </AuthContextProvider>
  );
}

export default App;
