import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/List";
import { Mail } from "@mui/icons-material";
import OurRjs from "./OurRjs";

import Logo from "./assets/images/Logo IPF.png";
import {
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  ListItemIcon,
  Drawer,
  Collapse,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useAuth } from "./context";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export default function MyDrawer({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { user, displayName } = useAuth();

  const [aboutUs, setAboutUs] = React.useState<boolean>(false);
  const [ourRjs, setOurRjs] = React.useState<boolean>(false);
  const [support,setSupport] = React.useState<boolean>(false);

  return (
    <>
      <Drawer open={open} anchor="right" onClose={onClose}>
        <Box
          sx={{ width: 260 }}
          role="presentation"
          display="flex"
          padding="0.5em"
          alignItems="center"
        >
          <Link href="/">
            <img src={Logo} alt="" style={{ height: "60px" }} />
          </Link>
          <Typography
            fontSize="0.9em"
            sx={{ textAlign: "center", marginLeft: "1em" }}
          >
            International
            <br />
            Punjabi Fever
          </Typography>
        </Box>
        <Box>
          <List sx={{maxWidth:"270px"}}>
            <ListItem
              disablePadding
              onClick={(e) => {
                setAboutUs(!aboutUs);
              }}
            >
              <ListItemButton
                sx={{
                  background: "#111",
                  padding: "0.5em",
                  borderRadius: "0.3em",
                  margin: "0.3em",
                }}
              >
                <ListItemText primary={"About Us"}></ListItemText>
                {aboutUs ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse
              in={aboutUs}
              timeout="auto"
              unmountOnExit
              sx={{
                background: "#111",
                padding: "1em",
                borderRadius: "0.3em",
                margin: "0.3em",
              }}
            >
              <List component="div" disablePadding>
                <Box maxWidth="100%">
                  <Typography fontSize="17px" marginBottom="1em">International Punjabi Fever</Typography>
                  <Typography fontSize="14px">
                  International Punjabi Fever is an Internet based radio in New Delhi,India. It is known for promoting Punjabi music,culture,entertainment and updates of the Punjabi industry worldwide. It broadcasts interviews with Punjabi artists, plays your favorite Punjabi tunes and provides updates on Punjabi events happening in your city.
                  </Typography>
                </Box>
              </List>
            </Collapse>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  background: "#111",
                  padding: "0.5em",
                  borderRadius: "0.3em",
                  margin: "0.3em",
                }}
                onClick={(e) => {
                  setOurRjs(!ourRjs);
                }}
              >
                <ListItemText primary={"Our RJs"} />
                {ourRjs ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse
              in={ourRjs}
              timeout="auto"
              unmountOnExit
              sx={{
                borderRadius: "0.3em",
                margin: "0.3em",
              }}
            >
              <List component="div" disablePadding>
                <Box maxWidth="100%">
                  <OurRjs/>
                </Box>
              </List>
            </Collapse>
            {/* <ListItem disablePadding>
              <ListItemButton
                sx={{
                  background: "#111",
                  padding: "0.5em",
                  borderRadius: "0.3em",
                  margin: "0.3em",
                }}
              >
                <ListItemText primary={"Settings"} />
              </ListItemButton>
            </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  background: "#111",
                  padding: "0.5em",
                  borderRadius: "0.3em",
                  margin: "0.3em",
                }}
                onClick={(e) => {
                  setSupport(!support);
                }}
              >
                <ListItemText primary={"Support"} />
                {support ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse
              in={support}
              timeout="auto"
              unmountOnExit
              sx={{
                borderRadius: "0.3em",
                margin: "0.3em",
              }}
            >
              <List component="div" disablePadding>
                <Box maxWidth="100%" >
                <a href="mailto:support@punjabifever.com" style={{color:"#fff", textDecoration:"none", padding:"1em", display:"flex"}}><Mail style={{marginRight:"0.4em"}} />support@punjabifever.com</a>
                </Box>
              </List>
            </Collapse>
          </List>
        </Box>
      </Drawer>
    </>
  );
}
