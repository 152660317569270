import React, { ReactNode, useState } from "react";
import { AuthContext } from "./context";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";

// Create the AuthContextProvider component
const AuthContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(
    localStorage.getItem("isAuthenticated") === "true"
  );
  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem("user") as string) || null
  );

  const [displayName, setDisplayName] = useState<string>("");

  React.useEffect(() => {
    localStorage.setItem("isAuthenticated", String(isAuthenticated));
    if (user) {
      if (user?.firstName) {
        let name = user?.firstName.charAt(0).toUpperCase();
        name += user?.lastName? user?.lastName?.charAt(0).toUpperCase(): "";
        setDisplayName(name);
      } else if (user?.displayName) {
        const names = user?.displayName.trim().split(/\s+/);

        let name = names?.[0].charAt(0).toUpperCase();

        name += names?.[1].charAt(0).toUpperCase();
        setDisplayName(name);
      }

      localStorage.setItem("user", JSON.stringify(user));
    } else {
      setDisplayName("");
      localStorage.removeItem("user");
    }
  }, [isAuthenticated, user]);

  const login = (userData: any) => {
    console.log(userData);
    setIsAuthenticated(true);
    setUser(userData);
  };

  const logout = async () => {
    try {
      setIsAuthenticated(false);
      setUser(null);
      signOut(auth);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {}
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, displayName, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
