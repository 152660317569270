import React, { useEffect, useState, FormEvent } from "react";
import { logEvent } from "firebase/analytics";
import "./App.css";
import {
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  Box,
  Popover,
  Fab,
  Button,
  Card,
  BottomNavigation,
  BottomNavigationAction,
  ListItem,
  List,
  TextField,
  Link,
  Alert,
  MenuList,
  MenuItem,
  ListItemIcon,
  InputAdornment,
} from "@mui/material";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import PauseCircleOutlineRoundedIcon from "@mui/icons-material/PauseCircleOutlineRounded";
import { Close, ConnectingAirportsOutlined, Chat as MChatICon, Share } from "@mui/icons-material";
import {
  Apple,
  FacebookOutlined,
  Favorite,
  Google,
  Send as SendIcon,
  PriorityHigh as PriorityHighIcon,
  Drafts as DraftsIcon,
} from "@mui/icons-material";
import Ad from "./Ad";
import Header from "./Header";
import Chat from "./Chat";
import Footer from "./Footer";
import MenuBar from "./MenuBar";
import SongThumbnail from "./SongThumbnail";
import AuthContextProvider from "./AuthContextProvider";
import { analytics, share, auth, googleProvider } from "./firebase";
import { signInWithPopup } from "firebase/auth";
import socket from "./ws-client.service";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import MainCrousel from "./MainCrousel";

import InstaImg from "./assets/images/instagram.png";
import FbImg from "./assets/images/facebook.png";
import YtImg from "./assets/images/youtube.png";
import RestoreIcon from "@mui/icons-material/Restore";

import {
  Home,
  Person,
  PresentToAllOutlined,
  Sensors,
} from "@mui/icons-material";
import Shows from "./Shows";
import PlayPanel from "./PlayPanel";
import Logo from "./assets/images/logo.png";
import HomeIcon from "./assets/images/Home Icon Final.png";
import ShowsIcon from "./assets/images/Radio Show Icon Final.png";
import ChatIcon from "./assets/images/Live Chat Icon Final.png";
import ShareIcon from "./assets/images/Share Icon Final Final.png";
import { useAuth } from "./context";

export const themeOptions = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "rgba(255, 33, 152, 1)",
    },
    secondary: {
      main: "#f50057",
    },
  },
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
});

// const shows = [
//   {
//     thumbnail: "",
//     desc: "Gedi Route with RJ Vishes",
//     time: "07:00 AM - 12:00 PM",
//   },
//   {
//     thumbnail: "",
//     desc: "Shades of love with RJ Kanwar",
//     time: "12:00 AM - 2:00 PM",
//   },
//   { thumbnail: "", desc: "The burrah Show", time: "2:00 AM - 4:00 PM" },
//   { thumbnail: "", desc: "Kudi Top noch", time: "4:00 AM - 6:00 PM" },
//   { thumbnail: "", desc: "Happy Hours ", time: "6:00 AM - 8:00 PM" },

//   {
//     thumbnail: "",
//     desc: "Gedi Route with RJ Vishes",
//     time: "07:00 AM - 12:00 PM",
//   },
//   {
//     thumbnail: "",
//     desc: "Shades of love with RJ Kanwar",
//     time: "12:00 AM - 2:00 PM",
//   },
//   { thumbnail: "", desc: "The burrah Show", time: "2:00 AM - 4:00 PM" },
//   { thumbnail: "", desc: "Kudi Top noch", time: "4:00 AM - 6:00 PM" },
//   { thumbnail: "", desc: "Happy Hours ", time: "6:00 AM - 8:00 PM" },

//   {
//     thumbnail: "",
//     desc: "Gedi Route with RJ Vishes",
//     time: "07:00 AM - 12:00 PM",
//   },
//   {
//     thumbnail: "",
//     desc: "Shades of love with RJ Kanwar",
//     time: "12:00 AM - 2:00 PM",
//   },
//   { thumbnail: "", desc: "The burrah Show", time: "2:00 AM - 4:00 PM" },
//   { thumbnail: "", desc: "Kudi Top noch", time: "4:00 AM - 6:00 PM" },
//   { thumbnail: "", desc: "Happy Hours ", time: "6:00 AM - 8:00 PM" },
// ];

function Main() {
  const audioRef = React.useRef<HTMLAudioElement>(null);
  const timerRef = React.useRef<any>(null);
  const [playing, setPlaying] = React.useState<any>(false);
  const [volume, setVolume] = React.useState<number>(100);
  const [progress, setProgress] = React.useState<number>(0);
  const [curTime, setCurTime] = React.useState<string>("23:59:59");
  const [ads, setAds] = React.useState<any>([]);
  const [thumbnail, setThumbnail] = React.useState<string | false>("");
  const [songTitle, setSongTitle] = React.useState<string>("Offline");
  const [streamStart, setStreamStart] = React.useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [userRole, setUserRole] = React.useState<any | null>(null);

  const [messages, setMessages] = React.useState<
    { message: string; name: string; photo: string }[]
  >([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [isOnline, setIsOnline] = React.useState<boolean>(navigator.onLine);

  const [bottomMenu, setBottomMenu] = useState<any>(0);

  const [shows, setShows] = useState<any[]>([]);

  const [showThumb, setShowThumb] = useState<string>("");

  const [displayShows, setDisplayShows] = useState<any[]>([]);

  const [formState, setFormState] = useState<number>(0);

  const [isNewUser, setIsNewUser] = useState<boolean>(false);

  const [mobile, setMobile] = useState<number>(0);

  const [errMsg, setErrMsg] = useState<string>("");

  const [regToken, setRegToken] = useState<string | null>(null);

  const { isAuthenticated, user, login, displayName } = useAuth();

  useEffect(() => {
    if (bottomMenu === 3) {
    }
  }, [bottomMenu]);

  React.useEffect(() => {
    getShows();
    fetchAds();
    logEvent(analytics, "listening");
    sockets();
    // getUserRole();
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);

      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (audioRef.current && audioRef.current.paused) setPlaying(false);
    else setPlaying(true);
  }, [audioRef.current?.paused]);



  React.useEffect(() => {
    const navigator_: any = window.navigator;
    if (isOnline && navigator_.userActivation.hasBeenActive) {
      play();
    } else {
      pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  React.useEffect(() => {
    if (audioRef.current) audioRef.current.volume = volume / 100;
  }, [audioRef, volume]);

  const sockets = async () => {
    await socket.connect();
    if (socket.client.id) {
      const { payload } = await socket.client.request("/m");
      changeThumbnail(payload.title);
      

      if (payload.stream_start_iso8601) {
        let ttime = new Date(Date.parse(payload.stream_start_iso8601));
        setStreamStart(`${ttime.toLocaleTimeString()} IST`);
      }
      // setStreamStart(payload.stream_start_iso8601)
      socket.client.onUpdate = (msg: string) => {
        const rec = JSON.parse(msg);
        if (rec.metadata) {
          changeThumbnail(rec.metadata.title);
        }
        if (rec.chat) {
          setMessages((msgs) => [...msgs, rec.chat]);
        }
      };
    }
  };

  const getShows = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}shows/get`
    );

    if (response.status === 200) {
      const data = await response.json();
      
      if (data) {
        setShows(data);
        // let sortedShows = 
        data.sort((a:any, b:any) => {
          const timeA = a.startTime.split(':').map(Number);
          const timeB = b.startTime.split(':').map(Number);
        
          const dateA:any = new Date();
          dateA.setHours(timeA[0], timeA[1], timeA[2], 0);
        
          const dateB:any = new Date();
          dateB.setHours(timeB[0], timeB[1], timeB[2], 0);
        
          return dateA - dateB;
        });
        setDisplayShows(data);
      }
    }
  };

  const setShowThumbnail = () => {
    const sortedShows = shows.sort(
      (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
    );
    
    // Get the current time
    const now = new Date();

    // Find the first event whose time is less than now
    const findShow = sortedShows.find((event) => new Date(event.time).getTime() < now.getTime());
    let thumb = `./images/default.jpg`;


    if (findShow) {
      thumb = `${process.env.REACT_APP_API_BASE_URL}${findShow.image}`;
    }
    setShowThumb(thumb);
    // console.log(thumb, findShow, sortedShows, displayShows ,"thumb");
  };

  // useEffect(() => {
  //   setShowThumbnail();
  //   const interval = setInterval(() => setShowThumbnail(), 10000);

  //   return () => clearInterval(interval);
  // }, [shows]);

  // const getUserRole = async () => {
  //   const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}userrole`);

  //   if (response.status == 200) {
  //     const data = await response.json();
  //     if (data) {
  //       setUserRole(data);
  //     }
  //   }
  // };

  const fetchAds = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}ad`);
    const result = await response.json();
    const Ads: any = {};
    result.docs.forEach((ad: any) => {
      ad.image = process.env.REACT_APP_API_BASE_URL + ad.image;
      Ads[ad.place] = ad;
    });
    setAds(Ads);
  };

  const changeThumbnail = async (title: string) => {
    /* if(!playing){
      setThumbnail(false);
      return;
    } */
    const regex = /\|(?<name>[ a-z0-9]+)\|/gm;
    const m = regex.exec(title);
    if (m?.groups?.name) {
      const id = m.groups.name.replace(" ", "");
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}songthumbnail/${id}`
      );
      const result = await response.json();
      if(result.thumbnail){
        setThumbnail(process.env.REACT_APP_API_BASE_URL + result.thumbnail);
      }
      else{
        setThumbnail(false)
      }

      
    } else {
      setThumbnail(false);
    }

    // set song title
    let temptitle = title
      
    if(temptitle.includes("|"))
      { 
        let titleArray = temptitle.split("|")
        temptitle = titleArray?.[0]??temptitle
        
      }
      
      if(temptitle.includes("-"))
        {
          let titleArray2 = temptitle.split("-")
          temptitle =  titleArray2?.[1]??temptitle
          
          
      }
      
      // let titleArray2 = titleArray?.[0].includes("-")? titleArray?.[0].split("-"):[]

    setSongTitle(temptitle);
      // set song title
  };

  const secToHHmmss = (secs: number | undefined): string => {
    try {
      if (!secs) return "";
      const hours = Math.floor(secs / 3600);
      const minutes = Math.floor(secs / 60) % 60;
      const seconds = Math.floor(secs % 60);

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    } catch (err: any) {
      return "00:00:00";
    }
  };

  const play = () => {
    const d = new Date();
    let currentSec = d.getSeconds() + d.getMinutes() * 60 + d.getHours() * 3600;
    const totalSec = 24 * 3600;
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      currentSec += 1;
      if (currentSec > totalSec) {
        const d = new Date();
        currentSec = d.getSeconds() + d.getMinutes() * 60 + d.getHours() * 3600;
      }
      setCurTime(secToHHmmss(currentSec));
      setProgress((currentSec / totalSec) * 100);
    }, 1000);
    setPlaying(true);
    if (audioRef.current)
      audioRef.current.src = process.env.REACT_APP_STREAM_URL as string;
    audioRef.current?.play();
  };

  const pause = () => {
    if (timerRef.current) clearInterval(timerRef.current);
    setPlaying(false);
    audioRef.current?.pause();
    if (audioRef.current) audioRef.current.src = "";
  };

  const playPause = () => {
    if (audioRef.current?.paused && isOnline) {
      play();
    } else {
      pause();
    }
  };

  const volumeChange = (event: Event, newValue: number | number[]) => {
    setVolume(newValue as number);
  };

  const checkMobile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrMsg("");
    const form = await Object.fromEntries(new FormData(e.currentTarget));
    setMobile(form?.mobile as unknown as number);
    const tenDigitRegex = /^\d{10}$/;
    if (!tenDigitRegex.test(form?.mobile as unknown as string)) {
      return setErrMsg("Please enter 10 digit valid mobile number");
    }
    // check mobile if exists
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}check-mobile`,
      {
        method: "POST",
        body: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const get = await response.json();
      if (!get) {
        setIsNewUser(true);
      }
      return setFormState(1);
    }
    // if exists then send otp otherwise enter user details
    // and register user
  };

  const handleRegisteration = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrMsg("");
    const form = await Object.fromEntries(new FormData(e.currentTarget));

    if (form.email === "") {
      const timestamp = Date.now();
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let randomValues = "";
      const charactersLength = characters.length;

      for (let i = 0; i < 10; i++) {
        randomValues += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      form.email += `${timestamp}${randomValues}@punjfever.in`;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(form?.email as unknown as string)) {
        return setErrMsg("Please enter valid email");
      }
    }

    if (!form?.firstName) {
      return setErrMsg("Please enter first name");
    }
    form.mobileNo = mobile as unknown as string;
    // form.role = userRole._id
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}register`,
      {
        method: "POST",
        body: JSON.stringify({
          user: form,
          registerType: "SelfRegister",
          token: regToken,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const get = await response.json();
      login(get);
      return setFormState(0);
    } else {
      const error = await response.json();
      if (error.message) {
        setErrMsg(error.message);
      }
    }
  };

  const verifyOtp = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrMsg("");
    const form = await Object.fromEntries(new FormData(e.currentTarget));
    // form.role = userRole._id
    form.mobile = mobile as unknown as string;
    if (isNewUser) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}verify-otp`,
        {
          method: "POST",
          body: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        let get = await response.text();
        console.log(get);
        setRegToken(get);
        setFormState(2);
      } else {
        return setErrMsg("Invalid OTP");
      }
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}clientlogin`,
        {
          method: "POST",
          body: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        let get = await response.json();

        if (get?.user) {
          login(get.user);
          setFormState(0);
        }
      } else {
        return setErrMsg("Invalid OTP");
      }
    }
  };

  const handleBottomMenuClose = () => {
    setBottomMenu(0);
  };

  const handleLogin = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;

        login(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <ThemeProvider theme={themeOptions}>
      <div style={{ background: "#000", minHeight: "100svh" }}>
        <Box
          id="main"
          // sx={{ background: "white url(./images/bg.jpg) no-repeat center", backgroundSize: "cover" }}
        >
          <MenuBar />
          <Box
            sx={{
              display: "flex",
              height: { xs: "100%", md: "calc(100svh - 180px)" },
            }}
          >
            <Box
              sx={{
                background: "#222",
                height: { xs: "100%", md: "calc(100svh - 180px)" },
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width={{ md: "125px" }}
              >
                <List
                  sx={{
                    display: { xs: "none", md: "flex" },
                    flexFlow: "column",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ListItem
                    button
                    sx={{ justifyContent: "center", marginBottom: "1em" }}
                    onClick={() => setBottomMenu(0)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "center",
                      }}
                    >
                      <img src={HomeIcon} height="30px" />
                      <Typography
                        color="#fff"
                        fontSize="14px"
                        variant="inherit"
                      >
                        Home
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem
                    button
                    sx={{ justifyContent: "center", marginBottom: "1em" }}
                    onClick={() => setBottomMenu(1)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "center",
                      }}
                    >
                      <img src={ShowsIcon} height="30px" />

                      <Typography
                        color="#fff"
                        fontSize="14px"
                        variant="inherit"
                      >
                        Shows
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem
                    button
                    sx={{ justifyContent: "center", marginBottom: "1em" }}
                    onClick={() => setBottomMenu(2)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "center",
                      }}
                    >
                      <img src={ChatIcon} height="30px" />

                      <Typography
                        color="#fff"
                        fontSize="14px"
                        variant="inherit"
                      >
                        Chat
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem
                    button
                    sx={{ justifyContent: "center", marginBottom: "1em" }}
                    onClick={share}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "center",
                      }}
                    >
                      <img src={ShareIcon} height="30px" />

                      <Typography
                        color="#fff"
                        fontSize="14px"
                        variant="inherit"
                      >
                        Share
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem
                    button
                    sx={{ justifyContent: "center", marginBottom: "1em" }}
                    onClick={() => setBottomMenu(4)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "center",
                      }}
                    >
                      {displayName ? (
                        <Typography
                          color="#fff"
                          fontWeight="600"
                          fontSize="20px"
                          variant="inherit"
                          sx={{
                            borderRadius: "50%",
                            border: "2px solid",
                            width: "1.2em",
                            height: "1.2em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0.1em",
                            lineHeight:"1em"
                          }}
                        >
                          {displayName}
                        </Typography>
                      ) : (
                        <Person sx={{ color: "#fff", fontSize: "30px" }} />
                      )}

                      <Typography
                        color="#fff"
                        fontSize="14px"
                        variant="inherit"
                      >
                        Profile
                      </Typography>
                    </Box>
                  </ListItem>
                </List>
              </Box>
            </Box>
            <Grid
              container
              spacing={0}
              overflow="auto"
              sx={{ padding: { md: "1em", xs: "" } }}
            >
              <Grid
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexFlow: "column",
                }}
                item
                xs={12}
                md={5}
                order={{ xs: 1, md: 2 }}
              >
                <Link
                  sx={{
                    background: "rgba(255, 33, 152, 1)",
                    color: "#fff",
                    padding: "0.3em 0.7em",
                    display: { xs: "none", md: "flex" },
                    borderRadius: "0.6em",
                    marginBottom: "0em",
                    fontSize: "18px",
                    fontWeight: "400",
                  }}
                  href="https://forms.gle/gaeMx9wjT12CuX2h9"
                  className="link-0"
                  target="_blank"
                >
                  <Favorite
                    style={{ marginTop: "0.1em", marginRight: "0.3em" }}
                  />
                  <span>REQUEST A SONG</span>
                </Link>
                <MainCrousel />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    sx={{
                      background: "rgba(255, 33, 152, 1)",
                      color: "#fff",
                      padding: "0.3em 0.8em",
                      display: { xs: "flex", md: "none" },
                      borderRadius: "0.4em",
                      marginTop: { md: "0", xs: "1em" },
                      fontWeight:"400"
                    }}
                    href="https://forms.gle/gaeMx9wjT12CuX2h9"
                    target="_blank"
                  >
                    <Favorite style={{ marginRight: "0.3em" }} />
                    <span >REQUEST A SONG</span>
                  </Link>

                  <Button
                    sx={{
                      display: { xs: "none", md: "flex" },
                      borderRadius: "0.8em",
                      fontSize: { md: "18px" },
                      padding: { md: "0.3em 0.8em" },
                    }}
                    variant="contained"
                    onClick={() => setBottomMenu(2)}
                    size="small"
                  >
                    <Box
                      sx={{
                        height: { md: "30px" },
                      }}
                    >
                      <img
                        src={ChatIcon}
                        style={{ marginRight: "0.5em", height: "100%" }}
                      />
                    </Box>
                    <span>Live Chat</span>
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1em",
                    marginBottom: "1em",
                  }}
                >
                  <Link
                    href="https://www.instagram.com/punjabifeverofficial?igsh=czFnb3BsZnp1ODdq"
                    sx={{
                      height: { md: "40px", xs: "30px" },
                      margin: { md: "0.5em 0.3em", xs: "0em 0.5em" },
                    }}
                    target="_blank"
                  >
                    <img
                      src={InstaImg}
                      alt=""
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </Link>
                  <Link
                    href="https://www.youtube.com/channel/UC7S0vKh55U83-ho6xvLkENQ"
                    sx={{
                      height: { md: "40px", xs: "30px" },
                      margin: { md: "0.5em 0.3em", xs: "0em 0.5em" },
                    }}
                    target="_blank"
                  >
                    <img
                      src={YtImg}
                      alt=""
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </Link>
                  <Link
                    href="https://www.facebook.com/share/eksAjKkT3jXt4Gtm/?mibextid=qi2Omg"
                    sx={{
                      height: { md: "40px", xs: "30px" },
                      margin: { md: "0.5em 0.3em", xs: "0em 0.5em" },
                    }}
                    target="_blank"
                  >
                    <img
                      src={FbImg}
                      alt=""
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </Link>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                order={{ xs: 2, md: 1 }}
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexFlow: "column",
                }}
              >
                <Box
                  sx={{
                    marginBottom: { xs: "9em", md: "0" },
                    maxWidth: { md: "600px", xs: "350px" },
                  }}
                >
                  {/* <Header /> */}
                  <audio ref={audioRef} autoPlay />

                  <SongThumbnail
                    thumbnail={thumbnail}
                    progress={progress}
                    curTime={curTime}
                    playing={playing}
                    playPause={playPause}
                    volume={volume}
                    volumeChange={volumeChange}
                    songTitle={songTitle}
                    startTime={streamStart}
                  />
                  {/*start new play button*/}
                  <Box
                    sx={{
                      display: {
                        xs: "none",
                        md: "block",
                        width: "100%",
                        marginTop: "1.5em",
                      },
                    }}
                  >
                    <PlayPanel
                      playPause={playPause}
                      playing={playing}
                      streamStart={streamStart}
                      thumbnail={showThumb}
                      songTitle={songTitle}
                    />
                  </Box>

                  {/* / start new play button*/}

                  {/* <Grid item xs={12} sx={{ display: { md: "none", xs: "block", paddingTop: '0px !important' } }}>
              <Typography variant='h5' fontWeight={700} textAlign='center' className='text-ellipsis'>{playing.name}</Typography>
              <Typography variant='subtitle2' fontWeight={700} textAlign='center' paddingLeft={5} paddingRight={5} className='text-ellipsis'>{playing.album}</Typography>
            </Grid> */}
                  {/* chat window show on desktop */}
                  {/* <Grid item md={4} sx={{ display: { xs: "none", md: "block" } }}>
                <Chat ad={ads?.chat} messages={messages} />
              </Grid> */}
                  {/* chat window show on desktop */}
                  {/* Mobile control display */}
                  <Container
                    sx={{
                      marginLeft: "48px",
                      display: { xs: "flex", md: "none" },
                      position: "relative",
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={2}>
                        {/* <IconButton aria-label="like" sx={{ background: "#f1d733", color: '#fff' }}>
                    <img src="./images/like.png" alt="like btn" width="50px" />
                  </IconButton> */}
                      </Grid>
                      {/* <Grid item xs={8} textAlign="center">
                      <IconButton
                        aria-label="pause"
                        onClick={playPause}
                        sx={{
                          color: "white",
                          marginTop: "10px",
                          background:
                            "transparent url(./images/pp_btn.png) no-repeat",
                          backgroundSize: "cover",
                        }}
                      >
                        {playing ? (
                          <PauseCircleOutlineRoundedIcon
                            sx={{ fontSize: 100 }}
                          />
                        ) : (
                          <PlayCircleOutlineRoundedIcon
                            sx={{ fontSize: 100 }}
                          />
                        )}
                      </IconButton>
                    </Grid> */}
                      {/* <Grid item xs={2}>
                    <IconButton
                      aria-label="like"
                      sx={{ background: "transparent" }}
                      onClick={share}
                    >
                      <img
                        src="./images/share.png"
                        alt="like btn"
                        width="60px"
                      />
                    </IconButton>
                  </Grid> */}
                    </Grid>
                    {/* <Fab
                      aria-label="like"
                      aria-describedby={id}
                      onClick={handleClick}
                      size="large"
                      sx={{
                        position: "absolute",
                        bottom: -35,
                        right: 10,
                      }}
                    >
                      <img
                        src="./images/msg button.png"
                        alt="chat btn"
                        width="50px"
                      />
                    </Fab> */}
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <Chat
                        ad={ads?.chat}
                        messages={messages}
                        onLogin={() => setBottomMenu(4)}
                      />
                    </Popover>
                  </Container>
                  {/* <Container
                sx={{ marginLeft: "48px", display: { xs: "flex", md: "none" } }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={12} color="#232323">
                    <img src="./images/waves.png" alt="Waves" width="100%" />
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      sx={{ height: 8 }}
                    />
                    <Grid container>
                      <Grid item xs={6} color="#232323">
                        <Typography fontWeight={700}>00:00</Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="end" color="#232323">
                        <Typography fontWeight={700}>{curTime}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container> */}
                </Box>
                {/* <Ad ad={ads?.bottom} /> */}

                <Box
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    zIndex: 10,
                    maxWidth: { xs: "100%", md: "600px" },
                    left: { xs: "auto", md: "0" },
                  }}
                >
                  {/* Live shows */}
                  {bottomMenu === 1 ? (
                    <>
                      <Box
                        component="section"
                        sx={{ padding: "0.5em" }}
                        maxHeight={600}
                        overflow="hidden"
                        zIndex={2}
                      >
                        <Button
                          sx={{
                            position: "absolute",
                            top: {md:"4em",xs:"1.5em"},
                            right: {md:"3em",xs:"0em"},
                            zIndex: "5",
                          }}
                          onClick={handleBottomMenuClose}
                        >
                          <Close />
                        </Button>
                        <List
                          sx={{
                            background: "rgba(0,0,0,0.95)",
                            borderRadius: "1em",
                            overflow: "auto",
                            height: "600px",
                          }}
                        >
                          <ListItem>
                            <div>
                              <Typography color="#fff" fontSize={22}>
                                Live Shows
                              </Typography>
                              <Typography fontSize={12} color="#555">
                                Radio Schedule for International Punjabi Fever
                              </Typography>
                            </div>
                          </ListItem>
                          {displayShows.length < 1 && (
                            <ListItem>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography color="#fff" fontSize={18}>
                                  No upcoming shows right now
                                </Typography>
                              </Box>
                            </ListItem>
                          )}
                          {displayShows.map((v, i) => {
                            
                            return <ListItem key={i}>
                                <img
                                  src={`${process.env.REACT_APP_API_BASE_URL}${v.image}`}
                                  style={{
                                    height: "50px",
                                    marginRight: "20px",
                                  }}
                                  alt=""
                                
                                />
                                <div>
                                  <Typography color="#fff">
                                    {v.title}
                                  </Typography>
                                  <Typography color="#555" fontSize={14}>
                                    {v.startTime}
                                    &nbsp;-&nbsp;
                                    {v.endTime}
                                  </Typography>
                                </div>
                              </ListItem>
                            
                          })}
                        </List>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* Chat */}
                  {bottomMenu === 2 ? (
                    <>
                      <Box
                        component="section"
                        sx={{ padding: "0.5em" }}
                        zIndex={2}
                      >
                        <Box
                          sx={{
                            background: "rgba(0,0,0,0.95)",
                            borderRadius: "1em",
                            padding: "1em",
                          }}
                        >
                          <Button
                            sx={{
                              position: "absolute",
                              top: {md:"4em",xs:"2em"},
                            right: {md:"3em",xs:"0.5em"},
                              zIndex: "5",
                            }}
                            onClick={handleBottomMenuClose}
                          >
                            <Close />
                          </Button>
                          <Chat
                            ad={ads?.chat}
                            messages={messages}
                            onLogin={() => setBottomMenu(4)}
                          />
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                  {bottomMenu === 4 && !user ? (
                    <>
                      <Box
                        component="section"
                        sx={{ padding: "0.5em" }}
                        zIndex={2}
                      >
                        <Box
                          sx={{
                            background: "rgba(0,0,0,0.95)",
                            borderRadius: "1em",
                            height: { xs: "60vh", md: "90vh" },
                            padding: "1em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            sx={{
                              position: "absolute",
                              top: {md:"2em",xs:"1.5em"},
                            right: {md:"1em",xs:"0em"},
                            }}
                            onClick={handleBottomMenuClose}
                          >
                            <Close sx={{ color: "#fff" }} />
                          </Button>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ flexFlow: "column" }}
                          >
                            <img src={Logo} height={100} alt="" />
                            <Typography color="#fff" fontSize={18}>
                              International Punjabi Fever
                            </Typography>
                            <Typography
                              color="#777"
                              textAlign="center"
                              fontSize={13}
                            >
                              World’s No. 1 Punjabi music hub
                              <br />
                              Gaane Wajjan Do
                            </Typography>
                            <Box display="flex" alignItems="center">
                              {/* <Button sx={{borderRadius:"30px"}} size="large" variant="contained">
                            <span>Login</span>
                          </Button>
                          <Typography color="#fff" padding="1em">or</Typography>
                          <Button sx={{borderRadius:"30px"}} size="large" variant="contained">
                            <span>Sign up</span>
                          </Button> */}
                            </Box>
                            <Box padding="0.5em">
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                width="100%"
                                marginBottom="1em"
                              >
                                <Button
                                  startIcon={<Google />}
                                  sx={{
                                    borderRadius: "30px",
                                    marginBottom: "0.5em",
                                    borderColor: "#fff",
                                    color: "#ddd",
                                    fontSize: { xs: "14px", md: "18px" },
                                  }}
                                  size="large"
                                  variant="outlined"
                                  onClick={handleLogin}
                                >
                                  <span>Continue with Google</span>
                                </Button>

                                <Typography
                                  color="#fff"
                                  display="inline-block"
                                  align="center"
                                >
                                  or
                                </Typography>
                              </Box>
                              {errMsg && (
                                <Alert
                                  severity="error"
                                  sx={{ marginBottom: "1em" }}
                                >
                                  {errMsg}
                                </Alert>
                              )}
                              {/* get mobile */}
                              {formState == 0 && (
                                <form method="post" onSubmit={checkMobile}>
                                  <TextField
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          +91
                                        </InputAdornment>
                                      ),
                                    }}
                                    placeholder="Mobile number"
                                    sx={{ marginBottom: "1em" }}
                                    fullWidth={true}
                                    name="mobile"
                                  ></TextField>
                                  <Button
                                    type="submit"
                                    fullWidth={true}
                                    sx={{ borderRadius: "30px" }}
                                    size="large"
                                    variant="contained"
                                  >
                                    <span>Next</span>
                                  </Button>
                                </form>
                              )}
                              {/* register */}
                              {formState === 2 && (
                                <form
                                  method="post"
                                  onSubmit={handleRegisteration}
                                >
                                  <TextField
                                    placeholder="First Name"
                                    sx={{ marginBottom: "1em" }}
                                    fullWidth={true}
                                    name="firstName"
                                  ></TextField>
                                  <TextField
                                    placeholder="Last Name"
                                    sx={{ marginBottom: "1em" }}
                                    fullWidth={true}
                                    name="lastName"
                                  ></TextField>
                                  <Typography
                                    color="#222"
                                    marginBottom="2em"
                                    marginTop="1em"
                                    textAlign="center"
                                    fontSize="14px"
                                    borderBottom="1px solid"
                                  ></Typography>
                                  <TextField
                                    placeholder="Email"
                                    sx={{ marginBottom: "1em" }}
                                    fullWidth={true}
                                    name="email"
                                  ></TextField>
                                  <Button
                                    type="submit"
                                    fullWidth={true}
                                    sx={{ borderRadius: "30px" }}
                                    size="large"
                                    variant="contained"
                                  >
                                    <span>Next</span>
                                  </Button>
                                </form>
                              )}
                              {/* Otp match */}
                              {formState === 1 && (
                                <form method="post" onSubmit={verifyOtp}>
                                  <TextField
                                    placeholder="OTP"
                                    sx={{ marginBottom: "1em" }}
                                    fullWidth={true}
                                    name="otp"
                                  ></TextField>
                                  <Button
                                    type="submit"
                                    fullWidth={true}
                                    sx={{ borderRadius: "30px" }}
                                    size="large"
                                    variant="contained"
                                  >
                                    <span>Login</span>
                                  </Button>
                                </form>
                              )}
                              {/* <Link href="#" marginTop="2em" fontSize={14} color="#ddd">
                          Reset Password
                        </Link> */}
                            </Box>
                            {/* <Button startIcon={<FacebookOutlined />} fullWidth={true} sx={{borderRadius:"30px" ,marginBottom:"1em",borderColor:"#fff", color:"#ddd"}}  size="large" variant="outlined">
                            <span>Continue with Facebook</span>
                          </Button> */}

                            {/* <Button startIcon={<Apple/>}  fullWidth={true} sx={{borderRadius:"30px", marginBottom:"1em",borderColor:"#fff", color:"#ddd"}} size="large" variant="outlined">
                            <span>Continue with Apple</span>
                          </Button> */}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                  <Box sx={{ display: { xs: "block", md: "none" } }}>
                    <PlayPanel
                      playPause={playPause}
                      playing={playing}
                      streamStart={streamStart}
                      thumbnail={showThumb}
                      songTitle={songTitle}
                    />
                  </Box>
                  <BottomNavigation
                    sx={{ display: { xs: "flex", md: "none" } }}
                    showLabels
                    value={bottomMenu}
                    onChange={(event, newValue) => {
                      setBottomMenu(newValue);
                    }}
                  >
                    <BottomNavigationAction
                      sx={{ fonSize: "10px" }}
                      label="Home"
                      icon={<img src={HomeIcon} height="25px" />}
                    />
                    <BottomNavigationAction
                      sx={{ fonSize: "10px" }}
                      label="Shows"
                      icon={<img src={ShowsIcon} height="25px" />}
                    />
                    <BottomNavigationAction
                      sx={{ fonSize: "10px" }}
                      label="Live Chat"
                      icon={<img src={ChatIcon} height="25px" />}
                    />
                    <BottomNavigationAction
                      sx={{ fonSize: "10px" }}
                      label="Share"
                      icon={
                        <img src={ShareIcon} onClick={share} height="25px" />
                      }
                    />
                    {/* <BottomNavigationAction sx={{fonSize:"10px"}}
                  label="Share"
                  icon={<PresentToAllOutlined />}
                /> */}
                    <BottomNavigationAction
                      sx={{ fonSize: "10px" }}
                      label="Profile"
                      icon={
                        user ? (
                          <Typography
                            color="#fff"
                            fontWeight="600"
                            fontSize="14px"
                            variant="inherit"
                            sx={{
                              borderRadius: "50%",
                              border: "2px solid",
                              width: "1.4em",
                              height: "1.4em",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0.3em",
                            }}
                          >
                            {displayName}
                          </Typography>
                        ) : (
                          <Person />
                        )
                      }
                    />
                  </BottomNavigation>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            display: { md: "flex", xs: "none" },
            background: "#620095",
            justifyContent: "center",
            alignItems: "center",
            height: { md: "90px", sm: "60px" },
          }}
          className="shadow"
        >
          <Typography
            fontSize="60px"
            fontWeight="600"
            align="center"
            color="#fff"
            className="agreloy"
          >
            THE BURRAH PROJECT 2024
          </Typography>
        </Box>
      </div>

      {/* <Shows/> */}
    </ThemeProvider>
  );
}

export default Main;
