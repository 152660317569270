import Nes from '@hapi/nes/lib/client'

const socket = {}
socket.client = new Nes.Client(process.env.REACT_APP_SOCKET_URL)

socket.connect = async () => {
    try {
        await socket.client.connect({})
    } catch (err) {
        if (err.statusCode === 401) {
            setTimeout(()=>{
                socket.connect()
            }, 10000)
        }
    }

}

socket.reconnect = async () => {
    //const token = await localDB.get('accessToken')
    console.log('Re-Connecting...')
    try {
        await socket.client.reauthenticate()
    } catch (err) {
        socket.disconnect()
    }
}

socket.disconnect = () => {
    socket.client.disconnect()
}

socket.client.onError = err => {
    console.error("Error", err, err.message)
}

socket.client.onConnect = async () => {}

socket.client.onDisconnect = (willReconnect, log) => {
}

export default socket

