import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import PauseCircleOutlineRoundedIcon from "@mui/icons-material/PauseCircleOutlineRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Slider,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { share } from "./firebase";

const PrettoSlider = styled(Slider)({
  color: "#f1d733",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#f1d733",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#f1d733",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const SongThumbnail: React.FC<{
  curTime: string;
  progress: number;
  playing: boolean;
  volume: number;
  playPause: () => void;
  volumeChange: (event: Event, newValue: number | number[]) => void;
  thumbnail: string | false;
  songTitle: string;
  startTime: string;
}> = ({
  curTime,
  progress,
  playing,
  volume,
  playPause,
  volumeChange,
  thumbnail,
  songTitle = "",
  startTime = "",
}) => {
  const [time, setTime] = React.useState<any>("");
  const [tt, settt] = React.useState(
    "https://assets.codepen.io/48941/photo-1627208550904-7abe585132d4.avif"
  );
  useEffect(() => {
    if (startTime) {
      let ttime = new Date(Date.parse(startTime));
      setTime(ttime.toLocaleString("en-IN", {}));
    }
  }, [startTime]);

  return (
    <React.Fragment>
      <Card
        sx={{
          width: "100%",
          borderRadius: { md: "1.5em", xs: "1.5em" },
          backgroundColor: "transparent",
          backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%), url(${thumbnail})`,
          backgroundSize: { md: "auto 35%, cover", xs: "cover" },
          backgroundPosition: { md: "center bottom, center", xs: "center" },
          backgroundRepeat: "no-repeat",
          transition: "background-image 0.5s ease-in-out",
          marginBottom: "1em",
          overflow: "inherit",
        }}
        className="ccard"
      >
        <CardContent
          sx={{
            padding: 0,
            position: "relative",
            paddingBottom: "0px !important",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left:{md:"1.4em", xs:"1em"} ,
              top: {md:"1.4em",xs:"1em"},
              borderRadius: "0.8em",
              overflow: "hidden",
              zIndex: "4",
            }}
          >
            <Box
            display="flex"
            alignItems="center"
              sx={{
                background: "#ff0000",
                padding: { md: "0.4em 0.8em", xs: "0.2em 0.4em" },
              }}
            >
              
                <FiberManualRecordIcon sx={{fontSize:{xs:"14px"}, fontWeight:"900"}} />
              <Typography
                sx={{
                  fontSize:{md:"24px",xs:"16px"},
                  display: "inline-block",
                  marginLeft: "0.3em",
                }}
              >
                LIVE
              </Typography>
            </Box>
            {/* <img className='glowingImage' src={`${thumbnail? thumbnail :'./images/default.jpg'}`} /> */}
          </Box>

          <img
            src={`${thumbnail ? thumbnail : "./images/default.jpg"}`}
            style={{ maxWidth: "100%", opacity: "0" }}
          />
          <img
            src={`${thumbnail ? thumbnail : "./images/default.jpg"}`}
            className="absolute-thumbnail"
          />

          <Grid
            container
            sx={{
              position: "absolute",
              bottom: "1em",
              padding: 2,
              display: { md: "flex", xs: "none" },
            }}
          >
            <Grid item xs={6} color="white">
              <Typography fontWeight={700}>00:00</Typography>
            </Grid>
            <Grid item xs={6} textAlign="end" color="white">
              <Typography fontWeight={700}>{curTime}</Typography>
            </Grid>
            <Grid item xs={12}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ height: 8 }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default SongThumbnail;
