import { createContext, useContext, useEffect } from "react";

export const AuthContext = createContext<any>(undefined);



export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context;
};