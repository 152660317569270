// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA0e5n6H6iWN84BtBT5mxodIHOivg-wcJk",
  authDomain: "punjabifever-a1363.firebaseapp.com",
  projectId: "punjabifever-a1363",
  storageBucket: "punjabifever-a1363.appspot.com",
  messagingSenderId: "599522018034",
  appId: "1:599522018034:web:2e1a34b7f55922b38b866b",
  measurementId: "G-PXB0GPDNZQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const share = () => {
  const navigator_: any = window.navigator;
  const data = {
    title: "Punjabi Fever is online",
    text : "Apne pasand de Punjabi gaane suno. Oh v apne phone te. Top punjabi tracks sunan layi click kro...",
    url: "https://punjabifever.in"
  };
  if(navigator_.canShare){
    navigator_.share(data)
  }else{
    navigator_.clipboard.writeText("Apne pasand de Punjabi gaane suno. Oh v apne phone te. Top punjabi tracks sunan layi click kro... https://punjabifever.in");
    alert("Copied...");
  }
}


export default app;