import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/List"

import Logo from "./assets/images/Logo IPF.png";
import {
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  ListItemIcon,
  Drawer,
  Collapse
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useAuth } from "./context";
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import MyDrawer from "./MyDrawer";

export default function MenuBar() {

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="shadow">
        <AppBar position="static" sx={{background:"#620095", height:{md:"90px",xs:"60px"}}}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link href="/">
                <Box sx={{height:{md:"90px",xs:"60px"}}}>
                <img src={Logo} style={{ maxHeight:"90%" }} />
                </Box>
              </Link>
              <Typography fontSize="1em" align="center" fontWeight="600" padding="0.5em 1em" sx={{display:{xs:"block",md:"none", color:"#fff"}}}>
              WORLD’S NO. 1 PUNJABI MUSIC HUB 
              </Typography>
              
            </div>
            <Typography fontSize="60px" fontWeight="600"  align="center" padding="0.5em 1em" sx={{display:{xs:"none",md:"block"}}} className="agreloy">
              GAANE WAJJAN DO
              </Typography>
            <div>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => setDrawerOpen(true)}
              >
                {/* {displayName ? <Typography
                          color="#fff"
                          fontWeight="600"
                          fontSize="26px"
                          variant="inherit"
                          sx={{
                            borderRadius: "50%",
                            border: "2px solid",
                            width: "1.4em",
                            height: "1.4em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0.3em",

                          }}
                        >
                          {displayName}
                        </Typography> :<ListIcon sx={{fontSize:{md:"60px",xs:"40px"}}} />} */}
                        <ListIcon sx={{fontSize:{md:"55px",xs:"40px"}}} />
              </IconButton>
              
              <MyDrawer open={drawerOpen} onClose={handleDrawerClose} />
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
