import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Box } from "@mui/material";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import YoutubeLogo from "../src/assets/images/3146788_youtube_logo_icon.png";
import DefaultImage from "../src/assets/images/default.jpg";

export default function MainCrousel() {
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    getCrousel()
  }, []);

  const getCrousel = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}crousel`
    );

    if (response.status == 200) {
      const data = await response.json();
      if (data.docs) {
        setImages(data.docs);
      }
    }
  };

  const youtubeLatest = async () => {
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${process.env.REACT_APP_YT_CHANNEL_ID}&maxResults=5&order=date&type=video&key=${process.env.REACT_APP_YT_API_KEY}&videoDuration=medium`
    );

    if (response.status === 200) {
      const data = await response.json();

      if (data.items) {
        setImages(data.items);
      }
    }
  };

  const CustomPrevArrow = (onClickHandler: any, hasPrev: any, label: any) => {
    return (
      hasPrev && (
        <button
          type="button"
          onClick={onClickHandler}
          title={label}
          className="crouselNavigator"
          style={{ left: "0.5em" }}
        >
          <ArrowBackIos sx={{ color: "#fff", fontSize: "25px" }}></ArrowBackIos>
        </button>
      )
    );
  };

  const CustomNextArrow = (onClickHandler: any, hasPrev: any, label: any) => {
    return (
      hasPrev && (
        <button
          type="button"
          onClick={onClickHandler}
          title={label}
          className="crouselNavigator"
          style={{ right: "0.5em" }}
        >
          <ArrowForwardIos
            sx={{ color: "#fff", fontSize: "25px" }}
          ></ArrowForwardIos>
        </button>
      )
    );
  };
  // useEffect(()=>{
  //   console.log(images)
  // },[images]);

  // return (
  //   <Box sx={{padding:{md:"1.5em 1em 1.5em 2em",xs:"0"}, maxWidth:"650px"}}>
  //   <Box sx={{borderRadius:"0.1em", overflow:"hidden", transition: "box-shadow 0.5s"}}>
  //       <Carousel
  //         infiniteLoop={true}
  //         showIndicators={true}
  //         showArrows={true}
  //         showThumbs={false}
  //         stopOnHover={true}
  //         // centerMode={true}
  //         showStatus={false}
  //         autoPlay={true}
  //         renderArrowPrev={CustomPrevArrow}
  //         renderArrowNext={CustomNextArrow}
  //         className="maincrousel"
  //       >
  //         {images.map((v,i) => {
  //           let image = `${v?.snippet?.thumbnails?.high?.url??""}`
  //           return (
  //             <div key={i}>
  //               <a href={`https://www.youtube.com/watch?v=${v?.id?.videoId}`} target="_blank" style={{display:"flex", width:"100%"}} >
  //                 <div style={{position:"absolute", display:"flex", justifyContent:"center", alignItems:"center", height:"100%", width:"100%"}}>
  //                 <img src={YoutubeLogo}  style={{maxWidth:"60px"}}  />
  //                 </div>
  //               <img src={image} />
  //               </a>
  //               {/* <p className="legend">Legend 1</p> */}
  //             </div>
  //           );
  //         })}
  //       </Carousel>
  //       </Box>
  //       </Box>

  // );

  return (
    <Box
      sx={{ padding: { md: "1em 1em 1em 2em", xs: "0" }, maxWidth: "520px" }}
    >
      <Box
        sx={{
          borderRadius: "0.1em",
          overflow: "hidden",
          transition: "box-shadow 0.5s",
        }}
      >
        {images.length > 0 ? (
          <Carousel
            infiniteLoop={true}
            showIndicators={true}
            showArrows={true}
            showThumbs={false}
            stopOnHover={true}
            // centerMode={true}
            showStatus={false}
            autoPlay={true}
            interval={3000}
            transitionTime={500}
            renderArrowPrev={CustomPrevArrow}
            renderArrowNext={CustomNextArrow}
            className="maincrousel"
          >
            {images.map((v, i) => {
              let image = `${process.env.REACT_APP_API_BASE_URL}${v.image}`;
              return (
                <div key={i}>
                  <a
                    href={v?.url ?? "#"}
                    target="_blank"
                    style={{ display: "inline-block" }}
                  >
                    <img src={image} />
                  </a>
                </div>
              );
            })}
          </Carousel>
        ) : (
          <div>
            <img src="./images/default.jpg" style={{maxWidth:"520px",border:"1px solid #fff"}} />
          </div>
        )}
      </Box>
    </Box>
  );
}
